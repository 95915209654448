/* stylelint-disable */
body {
  #wrapper {
    padding-left: 0 !important;
    #sidebar-wrapper {
      #hide-sidebar {
        display: none;
      }
    }

    &.toggled {

      #sidebar-wrapper {
        overflow: visible !important;
        transition: .1s;

        #hide-sidebar {
          display: block;
        }

        .fa-bell, .fa-envelope {
          &:before {
            color: $asb-white;
            font-size: 20px;
          }
        }
      }

      #rp-nav {
        display: none !important;
      }

      .close {
        display: block;
      }
    }

    #rp-nav {
      display: block !important;
    }

    .close {
      display: none;
      width: 35px;
      height: 35px;
      opacity: 1;
      margin: 15px 36px 7px 20px;
    }

    #topbar-first {
      height: 64px;
      padding-left: 0 !important;

      .portal {
        &-logo {
          float: left;
          margin-top: 15px;
          margin-left: 20px;
          width: 105px;
          overflow: hidden;
        }

        &-search {
          display: inline-block;
          height: 30px;
          float: left;
          margin-top: 15px;
          width: 30px;
        }

        &-menu {
          margin: 15px 36px 7px 20px !important;
          padding: 0 !important;
        }
      }

      #user-account-image {
        height: 35px;
        width: 35px;
      }

      #account-dropdown-link {
        margin-right: -3px;
      }
    }

    .topbar-wrapper {
      max-width: $max-topbar-area;
      margin: 0 auto;

      &.hidden, .topbar-actions.hidden, .nav.pull-left.hidden {
        display: block !important;
      }
    }

    .container {
      width: 100% !important;
      max-width: $max-content-area;
    }

    #sidebar-wrapper {
      background: $asb-darkgrey;
      margin-top: 64px;
      padding-top: 0;
      transition: .1s;

      .notifications {
        width: 100%;
        padding: 20px 23px 10px 23px;
        position: relative !important;
        float: none !important;

        .btn-group:last-of-type {
          margin-left: 25px;
        }

        .dropdown-menu {
          width: 290px !important;
        }

        #dropdown-messages {
          margin-left: -140px !important;
        }

        #dropdown-notifications {
          margin-left: -85px !important;
        }
      }

      #sidebar-inner-wrapper {
        position: absolute;
        top: 52px;
        left: 0;
        bottom: 64px;
        right: 0;
        overflow: auto;
      }
    }

    #topbar-first .nav > .account .dropdown-toggle {
      padding: 15px 0 16px 9px;
    }

    #topbar-first .notifications .btn-group > a {
      padding: 22px 12px 21px 12px;
    }

    .hide-mobile {
      display: none;
    }

    #topbar-first .portal-logo {
      width: 95px;
      height: 33px;
      overflow: visible;
      position: relative;

      svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    @media screen and (min-width: $screen-m) {
      &.toggled {
        #sidebar-wrapper {
          width: 250px !important;
        }
      }
      #sidebar-wrapper {
        width: 0;
      }
      .hide-mobile {
        display: block;
      }
      .notifications .dropdown-menu {
        min-width: 280px;
        width: 300px !important;

        #dropdown-messages,
        #dropdown-notifications {
          margin-left: -220px !important;
        }
      }
    }
  }
}

/* stylelint-enable */
