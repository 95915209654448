/* stylelint-disable */
body {
  .space-nav {
    margin-top: 64px;
    padding-left: 6px;
    padding-right: 17px;

    .navbar-nav.hidden {
      display: block !important;
    }
  }
}

/* stylelint-enable */
