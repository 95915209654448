/////
// Colors

// base colors
$asb-red:                                 #c80a14;
$asb-yellow:                              #fed70f;

$asb-green:                               #85ac61;

// font color
$asb-black:                               #000000;
$asb-white:                               #ffffff;

// gray shade
$asb-darkgrey:                            #383838;
$asb-menu-darkgrey:                       #b3b3b3;
$asb-grey:                                #dddddd;
$asb-lightgrey:                           #ebebeb;

// brand colors
$asb-primary:                             $asb-red;
$asb-secondary:                           $asb-yellow;

/////
// Layout

$max-page-area:                           1980px;
$max-topbar-area:                         1920px;
$max-content-area:                        1280px;

$max-menu-width:                          1572px;

$screen-s:                                576px;
$screen-m:                                768px;
$screen-l:                                992px;

$space-s:                                 20px;
$space-m:                                 20px;
$space-l:                                 40px;

$wrapper-s:                               20px;
$wrapper-m:                               20px;
$wrapper-l:                               40px;

/////
// Animation

$asb-fast:                                0.2s;
$asb-medium:                              0.5s;

/////
// Breakpoints

@media screen and (min-width: $screen-m) {
  $space-m:                               40px;
}

@media screen and (min-width: $screen-l) {
  $space-l:                               60px;
}
