/* stylelint-disable selector-max-id, declaration-no-important */
.login-container {
  background: $asb-yellow;
  font-size: 18px;

  .panel {
    box-shadow: none;
    border-radius: 20px;
    max-width: 560px !important;
    padding: 50px 20px;

    @media screen and (min-width: $screen-s) {
      padding: 50px 80px;
    }
  }

  .panel-heading {
    border-radius: 20px;
  }

  input[type="text"],
  input[type="input"],
  input[type="password"],
  input[type="email"] {
    border-radius: 20px;
    border: 1px solid black;
    margin: 15px 0 20px;
  }

  .col-md-4 {
    width: 100%;
  }

  .col-md-8 {
    width: 100%;
    text-align: center;
  }

  hr {
    display: none;
  }

  .btn-primary {
    border-radius: 20px;
    margin: 0 auto;
    display: block;
    font-size: 20px;
    font-weight: normal;
  }

  a.btn-primary {
    display: inline-block;
    margin-left: 50%;
    transform: translate(-50%, 50%);
    margin-bottom: 15px;
  }

  .text.text-center {
    display: none;
  }

  #password-recovery-link,
  #recoverPasswordBtn {
    color: $asb-darkgrey;
    font-size: 20px;
    line-height: 20px;
  }

  .modal-content {
    border-radius: 20px;

    .col-md-8 {
      margin-top: 25px;
    }
  }

  /* angemeldet bleiben */
  .field-login-rememberme {
    label {
      font-size: 16px;
      cursor: pointer;
      color: $asb-black;
    }
  }

  .regular-checkbox-box,
  .regular-checkbox:checked + .regular-checkbox-box {
    border: 1px solid $asb-black;
    background: $asb-white;
    border-radius: 0;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .regular-checkbox:checked + .regular-checkbox-box {
    &::before,
    &::after {
      content: '';
      background-color: $asb-black;
      position: absolute;
      width: 1px;
      height: 22px;
      left: 9px;
      top: -1px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

/* stylelint-enable */
