.skip-nav-link {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

/* stylelint-disable */
body {
  background: #ffffff;
}
body .panel .panel-body {
  font-size: 18px;
}
@media screen and (768px) {
  body .panel .panel-body {
    font-size: 20px;
  }
}
body #page-content-wrapper {
  padding: 100px 0 0 0;
}
body #sidebar-wrapper {
  left: auto;
  right: 0;
}
body .container-fluid {
  max-width: 1280px;
  margin: 0 auto;
}
body .space-layout-container {
  margin-top: 40px;
}
body #wrapper .modal-backdrop, body #wrapper .modal-backdrop.in {
  display: none !important;
}

/* stylelint-enable */
/* stylelint-disable */
body #wrapper {
  padding-left: 0 !important;
}
body #wrapper #sidebar-wrapper #hide-sidebar {
  display: none;
}
body #wrapper.toggled #sidebar-wrapper {
  overflow: visible !important;
  transition: 0.1s;
}
body #wrapper.toggled #sidebar-wrapper #hide-sidebar {
  display: block;
}
body #wrapper.toggled #sidebar-wrapper .fa-bell:before, body #wrapper.toggled #sidebar-wrapper .fa-envelope:before {
  color: #ffffff;
  font-size: 20px;
}
body #wrapper.toggled #rp-nav {
  display: none !important;
}
body #wrapper.toggled .close {
  display: block;
}
body #wrapper #rp-nav {
  display: block !important;
}
body #wrapper .close {
  display: none;
  width: 35px;
  height: 35px;
  opacity: 1;
  margin: 15px 36px 7px 20px;
}
body #wrapper #topbar-first {
  height: 64px;
  padding-left: 0 !important;
}
body #wrapper #topbar-first .portal-logo {
  float: left;
  margin-top: 15px;
  margin-left: 20px;
  width: 105px;
  overflow: hidden;
}
body #wrapper #topbar-first .portal-search {
  display: inline-block;
  height: 30px;
  float: left;
  margin-top: 15px;
  width: 30px;
}
body #wrapper #topbar-first .portal-menu {
  margin: 15px 36px 7px 20px !important;
  padding: 0 !important;
}
body #wrapper #topbar-first #user-account-image {
  height: 35px;
  width: 35px;
}
body #wrapper #topbar-first #account-dropdown-link {
  margin-right: -3px;
}
body #wrapper .topbar-wrapper {
  max-width: 1920px;
  margin: 0 auto;
}
body #wrapper .topbar-wrapper.hidden, body #wrapper .topbar-wrapper .topbar-actions.hidden, body #wrapper .topbar-wrapper .nav.pull-left.hidden {
  display: block !important;
}
body #wrapper .container {
  width: 100% !important;
  max-width: 1280px;
}
body #wrapper #sidebar-wrapper {
  background: #383838;
  margin-top: 64px;
  padding-top: 0;
  transition: 0.1s;
}
body #wrapper #sidebar-wrapper .notifications {
  width: 100%;
  padding: 20px 23px 10px 23px;
  position: relative !important;
  float: none !important;
}
body #wrapper #sidebar-wrapper .notifications .btn-group:last-of-type {
  margin-left: 25px;
}
body #wrapper #sidebar-wrapper .notifications .dropdown-menu {
  width: 290px !important;
}
body #wrapper #sidebar-wrapper .notifications #dropdown-messages {
  margin-left: -140px !important;
}
body #wrapper #sidebar-wrapper .notifications #dropdown-notifications {
  margin-left: -85px !important;
}
body #wrapper #sidebar-wrapper #sidebar-inner-wrapper {
  position: absolute;
  top: 52px;
  left: 0;
  bottom: 64px;
  right: 0;
  overflow: auto;
}
body #wrapper #topbar-first .nav > .account .dropdown-toggle {
  padding: 15px 0 16px 9px;
}
body #wrapper #topbar-first .notifications .btn-group > a {
  padding: 22px 12px 21px 12px;
}
body #wrapper .hide-mobile {
  display: none;
}
body #wrapper #topbar-first .portal-logo {
  width: 95px;
  height: 33px;
  overflow: visible;
  position: relative;
}
body #wrapper #topbar-first .portal-logo svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
@media screen and (min-width: 768px) {
  body #wrapper.toggled #sidebar-wrapper {
    width: 250px !important;
  }
  body #wrapper #sidebar-wrapper {
    width: 0;
  }
  body #wrapper .hide-mobile {
    display: block;
  }
  body #wrapper .notifications .dropdown-menu {
    min-width: 280px;
    width: 300px !important;
  }
  body #wrapper .notifications .dropdown-menu #dropdown-messages,
  body #wrapper .notifications .dropdown-menu #dropdown-notifications {
    margin-left: -220px !important;
  }
}

/* stylelint-enable */
/* stylelint-disable selector-max-id, declaration-no-important */
.login-container {
  background: #fed70f;
  font-size: 18px;
  /* angemeldet bleiben */
}
.login-container .panel {
  box-shadow: none;
  border-radius: 20px;
  max-width: 560px !important;
  padding: 50px 20px;
}
@media screen and (min-width: 576px) {
  .login-container .panel {
    padding: 50px 80px;
  }
}
.login-container .panel-heading {
  border-radius: 20px;
}
.login-container input[type=text],
.login-container input[type=input],
.login-container input[type=password],
.login-container input[type=email] {
  border-radius: 20px;
  border: 1px solid black;
  margin: 15px 0 20px;
}
.login-container .col-md-4 {
  width: 100%;
}
.login-container .col-md-8 {
  width: 100%;
  text-align: center;
}
.login-container hr {
  display: none;
}
.login-container .btn-primary {
  border-radius: 20px;
  margin: 0 auto;
  display: block;
  font-size: 20px;
  font-weight: normal;
}
.login-container a.btn-primary {
  display: inline-block;
  margin-left: 50%;
  transform: translate(-50%, 50%);
  margin-bottom: 15px;
}
.login-container .text.text-center {
  display: none;
}
.login-container #password-recovery-link,
.login-container #recoverPasswordBtn {
  color: #383838;
  font-size: 20px;
  line-height: 20px;
}
.login-container .modal-content {
  border-radius: 20px;
}
.login-container .modal-content .col-md-8 {
  margin-top: 25px;
}
.login-container .field-login-rememberme label {
  font-size: 16px;
  cursor: pointer;
  color: #000000;
}
.login-container .regular-checkbox-box,
.login-container .regular-checkbox:checked + .regular-checkbox-box {
  border: 1px solid #000000;
  background: #ffffff;
  border-radius: 0;
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.login-container .regular-checkbox:checked + .regular-checkbox-box::before, .login-container .regular-checkbox:checked + .regular-checkbox-box::after {
  content: "";
  background-color: #000000;
  position: absolute;
  width: 1px;
  height: 22px;
  left: 9px;
  top: -1px;
}
.login-container .regular-checkbox:checked + .regular-checkbox-box::before {
  transform: rotate(45deg);
}
.login-container .regular-checkbox:checked + .regular-checkbox-box::after {
  transform: rotate(-45deg);
}

/* stylelint-enable */
/* stylelint-disable */
body .space-nav {
  margin-top: 64px;
  padding-left: 6px;
  padding-right: 17px;
}
body .space-nav .navbar-nav.hidden {
  display: block !important;
}

/* stylelint-enable */
/* stylelint-disable */
.account.open .meta__icon--userMenu {
  visibility: visible;
  opacity: 1;
  top: 58px;
}

#topbar-first .dropdown.account > a,
#topbar-first .dropdown.account.open > a,
#topbar-first .dropdown.account > a:hover,
#topbar-first .dropdown.account.open > a:hover {
  background: #ffffff !important;
}

.meta__icon--userMenu {
  display: -ms-grid;
  display: grid !important;
  background: #ffffff !important;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  box-sizing: border-box;
  right: 0;
  left: 2.5vw !important;
  justify-content: center;
  text-align: center;
  width: 95%;
  padding: 50px;
  top: 50px;
  box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.04);
  transition: 0.15s;
  opacity: 0;
  visibility: hidden;
  position: fixed;
}
.meta__icon--userMenu .profile-link {
  border-bottom: 0;
  color: #383838 !important;
  transition: 0.5s !important;
  width: 100%;
}
.meta__icon--userMenu .profile-link:hover {
  color: #c80a14 !important;
}
@media screen and (min-width: 768px) {
  .meta__icon--userMenu {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    width: auto;
    padding: 30px 40px;
    position: absolute;
    left: auto !important;
  }
}
.meta__icon--userMenu a:last-child {
  margin-top: 15px;
}
.meta__icon--userMenu a {
  line-height: 1.9;
  font-size: 20px;
}

_:-ms-lang(x),
.meta__icon--userMenu {
  display: -ms-flexbox !important;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  width: 300px;
}

.btn-logout {
  background: #ffffff !important;
  border: solid 1px #000000 !important;
  border-radius: 20px;
  color: #000000 !important;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  height: 38px;
  outline: none;
  padding: 0 15px;
  transition: all 0.2s;
}
.btn-logout:hover {
  background: #383838 !important;
  color: #ffffff !important;
}

/* stylelint-enable */
/* stylelint-disable selector-max-id, declaration-no-important */
::placeholder {
  color: #626262 !important;
}

/* stylelint-enable */