/* stylelint-disable */
body {
  background: $asb-white;

  .panel .panel-body {
    font-size: 18px;

    @media screen and ($screen-m) {
      font-size: 20px;
    }
  }

  #page-content-wrapper {
    padding: 100px 0 0 0;
  }

  #sidebar-wrapper {
    left: auto;
    right: 0;
  }

  .container-fluid {
    max-width: $max-content-area;
    margin: 0 auto;
  }

  .space-layout-container {
    margin-top: 40px;
  }

  #wrapper {
    .modal-backdrop, .modal-backdrop.in {
      display: none !important;
    }
  }
}

/* stylelint-enable */
