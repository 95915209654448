/* stylelint-disable */

.account.open {
  .meta__icon--userMenu {
    visibility: visible;
    opacity: 1;
    top: 58px;
  }
}

#topbar-first .dropdown.account > a,
#topbar-first .dropdown.account.open > a,
#topbar-first .dropdown.account > a:hover,
#topbar-first .dropdown.account.open > a:hover {
  background: $asb-white !important;
}

.meta__icon--userMenu {
  display: -ms-grid;
  display: grid !important;
  background: $asb-white !important;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  box-sizing: border-box;
  right: 0;
  left: 2.5vw !important;
  justify-content: center;
  text-align: center;
  width: 95%;
  padding: 50px;
  top: 50px;
  box-shadow: 0 8px 7px 0 rgba(0, 0, 0, .04);
  transition: .15s;
  opacity: 0;
  visibility: hidden;
  position: fixed;

  .profile-link {
    border-bottom: 0;
    color: $asb-darkgrey !important;
    transition: $asb-medium !important;
    width: 100%;

    &:hover {
      color: $asb-primary !important;
    }
  }

  @media screen and (min-width: $screen-m) {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    width: auto;
    padding: 30px 40px;
    position: absolute;
    left: auto !important;
  }

  a:last-child {
    margin-top: 15px;
  }

  a {
    line-height: 1.9;
    font-size: 20px;
  }
}

_:-ms-lang(x),
.meta__icon--userMenu {
  display: -ms-flexbox !important;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  width: 300px;
}

.btn-logout {
  background: $asb-white !important;
  border: solid 1px $asb-black !important;
  border-radius: 20px;
  color: $asb-black !important;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  height: 38px;
  outline: none;
  padding: 0 15px;
  transition: all $asb-fast;

  &:hover {
    background: $asb-darkgrey !important;
    color: $asb-white !important;
  }
}

/* stylelint-enable */
